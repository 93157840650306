const DEBUG = false;
import axios from "axios";
import onChange from "on-change";
import Handlebars from "handlebars";

import CarTeaserList from "../site/_includes/partials/car-teaser-list.hbs";
import Pagination from "../site/_includes/molecules/pagination/pagination.hbs";

function getSlug(input) {
  return input.toLowerCase().replace(/ /g, "-");
}

const OFFER_PAGE_ROOT = {
  de: "fahrzeug/",
  en: "car/",
};

const OFFERS_PAGE_SLUG = {
  de: "fahrzeuge/",
  en: "cars/",
};

const LANG_BASE = {
  de: "",
  en: "en",
};

export default class CarSearch {
  constructor(settings) {
    this.settings = settings;
    this.domEls = this.getDomElements();
    this.language = document.querySelector("html").getAttribute("lang");
    if (!this.domEls) return;

    let _state = {
      activeFilters: {
        model: this.domEls.modelFilter.value,
        make: this.domEls.makeFilter.value,
      },
      ready: false,
      results: [],
      currentPage: 0,
      historyBack: false,
    };

    this.filterChanged = this.filterChanged.bind(this);
    this.stateChanged = this.stateChanged.bind(this);
    this.pageChanged = this.pageChanged.bind(this);
    this.resetFilters = this.resetFilters.bind(this);
    this.historyBack = this.historyBack.bind(this);

    // Throttle state updates
    this.state = onChange(_state, (path, value, prevValue) =>
      this.stateChanged(path, value, prevValue)
    );

    this.urlBase = `${window.location.protocol}//${window.location.host}`;

    this.domEls.makeFilter.addEventListener("change", this.filterChanged);
    this.domEls.resetButton.addEventListener("click", this.resetFilters);
    this.domEls.modelFilter.addEventListener("change", this.filterChanged);
    document.addEventListener("click", this.pageChanged);
    window.addEventListener("popstate", this.historyBack);

    this.allModels = this.domEls.modelFilter.innerHTML;
    this.allResultsHeadline = this.domEls.headline.textContent;

    this.getData().then((data) => {
      this.cars = data.cars;
      this.i18n = data.i18n;
      this.state.ready = true;
    });
  }

  stateChanged(path, value, prevValue) {
    if (DEBUG)
      console.log(
        "stateChanged: ",
        path,
        "value",
        value,
        "prevValue",
        prevValue
      );
    switch (path) {
      case "ready":
        if (value === true) {
          this.domEls.makeFilter.disabled = false;
          this.domEls.modelFilter.disabled = false;
        }
        break;
      case "activeFilters":
        if (
          value.make !== prevValue.make &&
          value.model !== prevValue.model &&
          value.model != "all"
        ) {
          this.filterByModel();
        } else {
          if (value.make !== prevValue.make) {
            this.filterByMake();
            this.updateModelFilter();
          }
          if (value.model !== prevValue.model) {
            this.filterByModel();
          }
        }
        break;
      case "results":
        this.updateResults();
      // Caution: fall through
      case "currentPage":
        this.updatePagination();
        if (value != prevValue) {
          this.updateResults();
        }
        break;
    }
  }

  filterByMake() {
    let make = this.state.activeFilters.make;
    if (make == "all") {
      let newURL =
        [this.urlBase, OFFERS_PAGE_SLUG[this.language], ""]
          .filter(Boolean)
          .join("/") + "/";
      this.state.results = this.getCarData(this.cars.categories.byTitle);
      if (!this.state.historyBack) {
        history.pushState({ make, model: "all" }, "", newURL);
      } else {
        this.state.historyBack = false;
      }
      this.domEls.headline.textContent = this.allResultsHeadline;
      this.updateModelFilter();
      this.updateLangSwitch(newURL);
    } else {
      let newURL =
        [this.urlBase, LANG_BASE[this.language], getSlug(make)]
          .filter(Boolean)
          .join("/") + "/";
      this.state.results = this.getCarData(
        this.cars.categories.byMake[make].cars
      );
      if (!this.state.historyBack) {
        history.pushState({ make }, "", newURL);
      } else {
        this.state.historyBack = false;
      }
      this.domEls.headline.textContent = make;
      this.updateModelFilter();
      this.updateLangSwitch(newURL);
    }
    this.domEls.makeFilter.value = make;
  }

  updateModelFilter() {
    if (this.state.activeFilters.make != "all") {
      let models = this.cars.categories.byMake[this.state.activeFilters.make]
        .models;
      let defaultOption = `<option value="all">${this.i18n.showAll}</option>`;
      let options = models.reduce((prev, next) => {
        let opt = `<option value="${next.model}">${next.model} (${next.count})</option>`;
        return prev + opt;
      }, defaultOption);
      this.domEls.modelFilter.innerHTML = options;
    } else {
      this.domEls.modelFilter.innerHTML = this.allModels;
    }
    this.domEls.modelFilter.value = this.state.activeFilters.model;
  }

  filterByModel() {
    let model = this.state.activeFilters.model;
    if (model == "all") {
      this.filterByMake();
    } else {
      let make = this.cars.categories.byModel[model].make;
      let newURL =
        [this.urlBase, LANG_BASE[this.language], getSlug(model)]
          .filter(Boolean)
          .join("/") + "/";
      this.domEls.makeFilter.value = make;
      this.state.activeFilters.make = make;
      this.domEls.modelFilter.value = this.state.activeFilters.model;
      this.state.results = this.getCarData(
        this.cars.categories.byModel[model].cars
      );
      this.domEls.headline.textContent = `${make} ${model}`;
      if (!this.state.historyBack) {
        history.pushState({ make, model }, "", newURL);
      } else {
        this.state.historyBack = false;
      }
      this.updateLangSwitch(newURL);
    }
  }

  updateResults() {
    let from = this.state.currentPage * this.settings.pageSize;
    let to = (this.state.currentPage + 1) * this.settings.pageSize;
    this.domEls.teaserListHolder.innerHTML = CarTeaserList({
      cars: this.state.results.slice(from, to),
      i18n: this.i18n,
      root: OFFER_PAGE_ROOT[this.language],
    });
  }

  updatePagination() {
    let numPages = Math.ceil(
      this.state.results.length / this.settings.pageSize
    );
    let pages = {
      hrefs: Array.from(Array(numPages)).map((_, idx) => `#${idx}`),
      href: {
        previous:
          this.state.currentPage > 0 ? `#${this.state.currentPage - 1}` : "",
        next:
          this.state.currentPage < numPages - 1
            ? `#${this.state.currentPage + 1}`
            : "",
      },
    };
    let currentPage = {
      url: `#${this.state.currentPage}`,
    };

    this.domEls.paginationHolder.innerHTML = Pagination({ pages, currentPage });
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  updateLangSwitch(currentUrl) {
    Array.from(document.querySelectorAll(".js-hreflang")).forEach(
      (langLink) => {
        let targetLang = langLink.dataset.lang;
        let targetUrl;
        if (currentUrl.includes(OFFERS_PAGE_SLUG[this.language])) {
          targetUrl = currentUrl.replace(
            OFFERS_PAGE_SLUG[this.language],
            OFFERS_PAGE_SLUG[targetLang]
          );
        } else {
          let path = currentUrl.substr(this.urlBase.length);
          let patt = /^\/([a-z]{2,2})\/(.*)\/$/i;

          let sourceLang = path.match(patt);
          if (sourceLang) {
            targetUrl = currentUrl.replace(
              sourceLang[1],
              LANG_BASE[targetLang]
            );
          } else {
            targetUrl = [this.urlBase, LANG_BASE[targetLang], path].join("/");
          }
        }

        targetUrl = targetUrl.replace(/(\w)\/\/(\w)/g, "$1/$2");

        langLink.href = targetUrl;
      }
    );
  }

  pageChanged(event) {
    if (!event.target.closest(".js-pagination")) return;
    let href = event.target.closest("a").getAttribute("href");
    let patt = /(\d*)\/?$/i;
    let result = patt.exec(href);
    if (!result.length || !result[1]) return;
    event.preventDefault();
    if (!this.state.results.length)
      this.state.results = this.getCarData(this.cars.categories.byTitle);
    this.state.currentPage = Number(result[1]);
  }

  getCarData(carKeys) {
    return carKeys.map((item) => {
      return this.cars.data[item.key];
    });
  }

  filterChanged(event) {
    let val = event.target.value;
    this.state.currentPage = 0;
    if (event.target == this.domEls.makeFilter) {
      this.state.activeFilters = {
        make: val,
        model: "all",
      };
    }
    if (event.target == this.domEls.modelFilter) {
      this.state.activeFilters = {
        make: this.state.activeFilters.make,
        model: val,
      };
    }
  }

  resetFilters() {
    this.state.activeFilters = { model: "all", make: "all" };
  }

  getDomElements() {
    let els = {};
    for (let el in this.settings.els) {
      let found = document.querySelector(this.settings.els[el]);
      if (!found) {
        if (DEBUG)
          console.warn(
            `CarSearch init error: Element ${el} with selector ${this.settings.els[el]} not found on page`
          );
        return false;
      }
      els[el] = found;
    }
    return els;
  }

  historyBack(event) {
    let make, model;
    if (!event.state) {
      make = "all";
      model = "all";
    } else {
      make = event.state.make;
      model = event.state.model || "all";
    }
    this.state.historyBack = true;
    this.state.activeFilters = { make, model };
  }

  async getData() {
    let result = await axios.get(`${this.urlBase}/car-data.json`);
    return {
      cars: result.data.carData[this.language],
      i18n: result.data.i18n[this.language],
    };
  }
}
